import { Container, Link, Typography } from "@mui/material";
import { useEffect } from "react";

function Contact() {
    useEffect(() => {
        document.title = 'Heavenly Heroes - App Privacy Policy';
    }, []);
    return (
        <Container maxWidth="lg" sx={{ pt: 3, textAlign: 'left' }}>
            <Typography variant="h1" color="initial" sx={{ fontWeight: 'bold', mb: 3 }}>Contact</Typography>
            <Typography variant="body1" color="initial" sx={{ mr: 40 }}>
                &emsp;Please contact us at <Link href="mailto:infoheavenlyheroes@gmail.com">infoheavenlyheroes@gmail.com</Link>
            </Typography>
        </Container>
    );
}

export default Contact