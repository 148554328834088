import { Container, Typography } from "@mui/material";
import { useEffect } from "react";

function AppPrivacyPolicy() {
    useEffect(() => {
        document.title = 'Heavenly Heroes - App Privacy Policy';
    }, []);
    return (
        <Container maxWidth="lg" sx={{ pt: 3, textAlign: 'left' }}>
            <Typography variant="h1" color="initial" sx={{ fontWeight: 'bold', mb: 3 }}>App Privacy Policy</Typography>
            <Typography variant="body1" color="initial">
                &emsp;Heavenly Heroes is developed by 3DCoded and is designed to provide an engaging and entertaining experience for users. <br />
                &emsp;We understand the importance of protecting the privacy of our users, and we are committed to safeguarding their personal information. This privacy policy explains how we collect, use, and disclose the personal information we collect from users of our app. <br />
                <b>Personal Information We Collect:</b><br />
                &emsp;We only collect email addresses from users when they submit a feature request or bug report. We do not collect any other personally identifiable information from users, such as their name, address, or phone number. <br />

                <b>How We Use Personal Information:</b><br />
                &emsp;We only use the email addresses we collect to respond to feature requests and bug reports submitted by our users. We do not share or disclose email addresses to third parties or use them for any other purpose. <br />

                <b>Third-Party Services:</b><br />
                &emsp;Our app does not use any third-party services that collect or share user information. <br />

                <b>Data Retention:</b><br />
                &emsp;We retain the email addresses submitted for feature requests and bug reports for as long as necessary to address the requests and issues submitted. <br />

                <b>Changes to Our Privacy Policy:</b><br />
                &emsp;We may update our privacy policy from time to time. If we make material changes to how we handle user information, we will notify users through a notice in the app or by other means. <br />

                <b>Contact Us:</b><br />
                &emsp;If you have any questions or concerns about our privacy policy, please contact us at infoheavenlyheroes@gmail.com.</Typography>
        </Container>
    );
}

export default AppPrivacyPolicy