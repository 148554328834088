import Button from '@mui/material/Button';
import './App.css';
import Container from '@mui/material/Container'
import Typography from '@mui/material/Typography'
import { Box, Link } from '@mui/material';

function App() {
  return (
    <Container maxWidth="lg" sx={{ pt: 3, textAlign: 'center' }}>
      <Typography variant="h1" color="initial" sx={{ fontWeight: 'bold' }}>Heavenly Heroes</Typography>
      <Typography variant="h2" color="initial" sx={{ fontWeight: 500 }}>The Coptic Saint App for Kids</Typography>
      <Link href="https://apps.apple.com/app/1660983985" target="_blank"><img src="/static/appstore.svg" /></Link>
      <Typography variant="h3" color="initial" sx={{ fontWeight: 'bold' }}>First, learn about the saints</Typography>
      <Box component="img" src="/static/screenshots/learn-list.png" sx={{ maxWidth: '48%', mr: 1, my: 1, border: '1px solid black', borderRadius: 10 }} />
      <Box component="img" src="/static/screenshots/learn-grid.png" sx={{ maxWidth: '48%', ml: 1, my: 1, border: '1px solid black', borderRadius: 10 }} />
      <Typography variant="h3" color="initial" sx={{ fontWeight: 'bold' }}>Then, play to strengthen your memory</Typography>
      <Box component="img" src="/static/screenshots/play-off-limits.png" sx={{ maxWidth: '48%', mr: 1, my: 1, border: '1px solid black', borderRadius: 10 }} />
      <Box component="img" src="/static/screenshots/play-odd-one-out.png" sx={{ maxWidth: '48%', ml: 1, my: 1, border: '1px solid black', borderRadius: 10 }} />
      <Box>
        <Button variant="text" color="primary" type="link" href="/contact">
          Contact
        </Button>
        <Button variant="text" color="primary" type="link" href="/app-privacy-policy">
          App Privacy Policy
        </Button>
      </Box>
    </Container>
  );
}

export default App;
